import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LANG, LANG_EN, LANG_RU } from 'data/constants';
import { RootState } from '../store';

const initialState = { ...DEFAULT_LANG };

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    en: (state) => {
      state.value = LANG_EN;
    },
    ru: (state) => {
      state.value = LANG_RU;
    },
    toggleLang: (state) => {
      state.value = state.value === LANG_RU ? LANG_EN : LANG_RU;
    },
  },
});

export const { en, ru, toggleLang } = langSlice.actions;

export const selectLang = (state: RootState) => state.lang.value;

export default langSlice.reducer;
