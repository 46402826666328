import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_THEME, THEME_DARK, THEME_LIGHT } from 'data/constants';
import { RootState } from '../store';

const initialState = { ...DEFAULT_THEME };

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    dark: (state) => {
      state.value = THEME_DARK;
    },
    light: (state) => {
      state.value = THEME_LIGHT;
    },
    toggleTheme: (state) => {
      state.value = state.value === THEME_LIGHT ? THEME_DARK : THEME_LIGHT;
    },
  },
});

export const { dark, light, toggleTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme.value;

export default themeSlice.reducer;
