import MainPage from 'pages/MainPage/MainPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { Navigate } from 'react-router-dom';

const ROUTES_LIST = [
  { path: '/', component: <MainPage /> },
  { path: '/404', component: <NotFoundPage /> },
  { path: '*', component: <Navigate to="/404" /> },
];

export default ROUTES_LIST;
