import { ReactNode } from 'react';
import Header from 'components/Header/Header';
import { ToastContainer } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import { selectTheme } from 'store/reducers/themeSlice';
import { THEME_LIGHT } from 'data/constants';
import s from './Layout.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }: { children: ReactNode }) => {
  const theme = useAppSelector(selectTheme);

  return (
    <div
      className={`${s.appWrapper} ${theme === THEME_LIGHT && s.appWrapperLight}`}
      id="app-wrapper"
    >
      <div className={s.mainContainer} id="main-container">
        {children}
      </div>
    </div>
  );
};

export default Layout;
