import About from 'components/About/About';
import s from './MainPage.module.scss';

const MainPage = () => {
  return (
    <div className={s.mainPage}>
      <About />
    </div>
  );
};

export default MainPage;
