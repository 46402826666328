const dict = {
  en: {
    textBest: 'best',
    textWork: 'commercial',
    textPersonal: 'personal',
    projects: 'Projects',
    skills: 'Skills',
    contacts: 'Contacts',
    contactMe: 'Contact me',
    opening: "Hello, I'm Andrey 👋",
    openingSites: 'I build sites & services with',
    workWithMe: 'Work with me',
    seeMyProjects: 'My projects',
    frontText1: 'I got into WEB-development in 2019.',
    frontText2: 'Since then I completed',
    frontText3: '50+ projects',
    frontText4: 'You can see some of them down below.',
    frontText5: 'You can contact me via e-mail or Telegram 👇',
    contactsFormText01: 'Thank you for your submission!',
    contactsFormText02: 'I’ll try my best to get back to you!',
    contactsFormText03: 'Go Back',
    contactsFormText04: 'Name*',
    contactsFormText05: 'From 3 to 60 symbols',
    contactsFormText06: 'Contacts (telegram, e-mail, etc.)*',
    contactsFormText07: 'From 3 to 60 symbols',
    contactsFormText08: 'Message*',
    contactsFormText09: 'From 10 to 3000 symbols',
    contactsFormText10: 'Send',
    contactsFormText11: 'Successfully submitted',
    contactsFormText12: 'Submission failed',
    jobTitle: 'Software engineer',
  },
  ru: {
    textBest: 'Лучшие',
    textWork: 'Коммерческие',
    textPersonal: 'Личные',
    projects: 'Проекты',
    skills: 'Навыки',
    contacts: 'Контакты',
    contactMe: 'Контакты',
    opening: 'Привет, я Андрей 👋',
    openingSites: 'Я делаю сайты и сервисы на',
    workWithMe: 'Написать мне',
    seeMyProjects: 'Мои проекты',
    frontText1: 'Я занимаюсь WEB-разработкой с 2019.',
    frontText2: 'За это время я завершил',
    frontText3: '50+ проектов',
    frontText4: 'Некоторые из них можно увидеть здесь.',
    frontText5: 'Можете написать мне на почту или в Telegram 👇',
    contactsFormText01: 'Спасибо за обращение!',
    contactsFormText02: 'Я отвечу при первой возможности!',
    contactsFormText03: 'Назад',
    contactsFormText04: 'Имя*',
    contactsFormText05: 'От 3 до 60 символов',
    contactsFormText06: 'Контакты (telegram, e-mail и т.д.)*',
    contactsFormText07: 'От 3 до 60 символов',
    contactsFormText08: 'Сообщение*',
    contactsFormText09: 'От 10 до 3000 символов',
    contactsFormText10: 'Отправить',
    contactsFormText11: 'Успешно отправлено',
    contactsFormText12: 'Не удалось отправить',
    jobTitle: 'Software engineer',
  },
};

export default dict;
