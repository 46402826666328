import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import themeReducer from './reducers/themeSlice';
import langReducer from './reducers/langSlice';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    lang: langReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
