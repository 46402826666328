/* eslint-disable react/require-default-props */
import { IMotionProps } from 'data/types';
import { motion, Variants } from 'framer-motion';

export const itemsAnimation: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: { delay: custom * 0.45 },
  }),
};

const MotionWrapper: React.FC<IMotionProps> = ({
  children,
  timeDelay,
  yStart,
  xStart,
  userStiffness,
  className,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: yStart || 0, x: xStart || 0 }}
      transition={{ type: 'spring', stiffness: userStiffness || 30, delay: timeDelay || 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1, y: 0, x: 0 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default MotionWrapper;
