import MotionWrapper, { itemsAnimation } from 'components/UI/MotionWrapper';
import { LANG_RU, THEME_DARK, THEME_LIGHT } from 'data/constants';
import dict from 'data/dict';
import { TypeAnimation } from 'react-type-animation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectLang, toggleLang } from 'store/reducers/langSlice';
import { selectTheme, toggleTheme } from 'store/reducers/themeSlice';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useState } from 'react';
import s from './About.module.scss';
import ThemeSwitch from '../UI/Switchers';

const About = () => {
  const theme = useAppSelector(selectTheme);
  const lang = useAppSelector(selectLang);
  const dispatch = useAppDispatch();
  const [contactsActive, setContactsActive] = useState(false);
  const [langsActive, setLangsActive] = useState(false);

  setTimeout(() => {
    setContactsActive(true);
  }, 5000);

  setTimeout(() => {
    setLangsActive(true);
  }, 1600);

  return (
    <section id="about" className={`${s.aboutSec} ${theme === THEME_LIGHT && s.aboutWrapperLight}`}>
      <div className={`${s.aboutWrapper}`}>
        <div className={s.aboutItem}>
          <MotionWrapper timeDelay={0} yStart={-50}>
            <h1 className={s.textBig}>{dict[lang].opening}</h1>
          </MotionWrapper>
          <MotionWrapper timeDelay={0.8} xStart={-80}>
            <h2 className={s.textJobTitle}>{dict[lang].jobTitle}</h2>
          </MotionWrapper>
          <MotionWrapper timeDelay={2.4} yStart={50}>
            <p className={s.frontText}>{dict[lang].frontText5}</p>
          </MotionWrapper>
        </div>
      </div>
      {contactsActive && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          className={s.contactsContainer}
        >
          <motion.a
            custom={0}
            variants={itemsAnimation}
            href="https://telegram.me/a9949"
            target="_blank"
            rel="noreferrer"
            className={`${s.contactsItem} ${s.contactsItemBig}`}
          >
            <i className="fa-solid fa-paper-plane" />
          </motion.a>

          <motion.a
            custom={2}
            variants={itemsAnimation}
            href="https://github.com/tlkv"
            target="_blank"
            rel="noreferrer"
            className={s.contactsItem}
          >
            <i className="fa-brands fa-github" />
          </motion.a>

          <motion.a
            custom={4}
            variants={itemsAnimation}
            href="mailto:tlkv1207@gmail.com"
            target="_blank"
            rel="noreferrer"
            className={s.contactsItem}
          >
            <i className="fa-solid fa-envelope" />
          </motion.a>

          <motion.div custom={6} variants={itemsAnimation}>
            <ThemeSwitch checked={theme !== THEME_DARK} onClick={() => dispatch(toggleTheme())} />
          </motion.div>

          <motion.div custom={8} variants={itemsAnimation}>
            <Button
              type="button"
              className={`${s.controlsButton} ${s.langButton} ${lang === LANG_RU && s.langRu}`}
              onClick={() => dispatch(toggleLang())}
            >
              Lang
            </Button>
          </motion.div>
        </motion.div>
      )}
    </section>
  );
};

export default About;
