import { LangState, ThemeState } from './types';

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

export const LANG_RU = 'ru';
export const LANG_EN = 'en';

export const SCROLL = 30;
export const MAX_SCROLL = 700;

export const DEFAULT_THEME: ThemeState = {
  value: THEME_DARK,
};

export const DEFAULT_LANG: LangState = {
  value: LANG_EN,
};

export const TIMEOUT = 2500;
