import s from './NotFoundPage.module.scss';

const NotFoundPage = () => {
  return (
    <div className={s.notFoundPage}>
      <div className={s.picture} />
    </div>
  );
};

export default NotFoundPage;
